<template>
  <div class="rating__diagram">
      <div class="rating__diagram-container">
          <h3 class="rating__diagram-text">{{labelText}}</h3>
          <div class="rating__diagram-chart">
            <BarChart :chartData="chartData" :options="options"/>
          </div>
      </div>
  </div>
</template>

<script>
import BarChart from '@/components/Statistic/BarChart';
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "RatingDiagram",
  props: ['dataToVisualizeLabels', 'dataToVisualizeValues', 'labelText'],
  plugins: [ChartDataLabels],
  components: {
    BarChart
  },
   data () {
    return {
      chartData: null,
      options: null,
    }
  },
  mounted() {
     this.fillData()
  },
  methods: {
    fillData() {
       this.chartData = {
          labels: this.dataToVisualizeLabels,
            datasets: [
              {
                label: "Количество баллов",
                backgroundColor: 'rgba(226, 105, 35, 0.664)',
                borderWidth: 2,
                borderColor: 'rgba(226, 105, 35)',
                borderSkipped: false,
                data: this.dataToVisualizeValues,
                datalabels: {
                  color: 'gray',
                  fontColor: "gray",
                },
                
              },
            ]
          }
      this.options =  {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
                display: true,
                position: 'bottom',
                labels: {
                    fontColor: "gray",
                }
            },
        layout: {
          padding: {
            top: 30
          }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                     fontColor: "gray", 
                },
                stacked: true,
                  gridLines: {
                      display: true,
                      // color: "gray"
                  },
            }],
             xAxes: [{
                  stacked: true,
                  gridLines: {
                      display: false,
                  },
                   ticks: {
                  fontColor: "gray", // this here
                },
                }],
        },
        plugins: {
            datalabels: {
              color: 'white',
              labels: {
                title: {
                  font: {
                    weight: 'bold'
                  }
                }
              }
              }
        }
      }
    }
  },
  watch: {
    dataToVisualizeLabels: function() {
      this.fillData()
    }
  }
}
</script>

<style scoped>

</style>