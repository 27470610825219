<template>
   <div class="rating">
     <Header></Header>
    <div class="content content--extended">
      <div class="container-header">
        <p>Рейтинг NTA</p>
      </div>
      <div class="rating__container">
        <h3 class="rating__title">Онлайн рейтинг УВА по ТБ по направлению NTA</h3>
        <div class="rating__select-wrapper">
            <select v-on:change="setRatingType($event.target.value)" class="rating__select-dropdown">Выберите тип рейтинга
                  <option value="articles" selected>Рейтинг по постам</option>
                  <option value="activity">Рейтинг по активностям</option>
                  <option value="summary" disabled>Общий рейтинг</option>
            </select>
            <select class="rating__select-dropdown"
                    @change="setRatingPeriod($event.target)"
            >Период
                   <option v-for="option in quarterOptionsList"
                                  :key="option.id"
                                  :value="option.id"
                          >
                                {{option.name}}
                   </option>
            </select>
        </div>
            <RatingDiagram
                           :label-text="labelText"
                           :data-to-visualize-labels="dataLabels"
                           :data-to-visualize-values="dataValues"
            ></RatingDiagram>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>

import RatingService from "@/services/rating.service"
import Header from "@/components/Assets/Header";
import Footer from "@/components/Assets/Footer";
import RatingDiagram from "./Rating/RatingDiagram";
import QuarterGenerator from "../utils/QuarterGenerator";



export default {
  name: "Rating",
  data() {
    return {
       dataLabels: null,
       dataValues: null,
       labelText: "Рейтинг УВА по ТБ по активностям",
       selectedDatePeriod: null,
       ratingType: 'articles',
       dateQuery: {startDate: '2021-09-01', endDate: '2021-11-30'},
       quarterOptionsList: QuarterGenerator.generateQuarters('2021-09-01')
    }
  },
  components: {
      RatingDiagram,
      Header,
      Footer
  },
  mounted() {
     this.loadRatingData()
  },
  methods: {
      loadRatingData(){
          let ratingTypes = {
            'activity': () => RatingService.getActivityRating(this.dateQuery),
            'articles': () => RatingService.getArticlesRating(this.dateQuery),
            // 'summary': RatingService.getActivityRating({startDate: '2021-09-01', endDate: '2021-11-30'})
          }

          let ratingLabelTypes = {
            'activity': 'Рейтинг УВА по ТБ по активностям',
            'articles': 'Рейтинг УВА по ТБ по статьям',
            'summary': 'Обший рейтинг УВА по ТБ по направлению NTA'
          }

          this.setRatingTitle(ratingLabelTypes[this.ratingType])

          ratingTypes[this.ratingType]().then(response => {
            this.setRatingValue(response)
          })

      },
      setRatingValue(response) {
          this.dataLabels = response.data.labels
          this.dataValues = response.data.score
      },
      setRatingTitle(labelText) {
          this.labelText = labelText;
      },
      setRatingPeriod(event){
          this.dateQuery =this.quarterOptionsList[event.value - 1].datesPeriod
      },
      setRatingType(value) {
          this.ratingType = value
      }
  },
  watch: {
    ratingType: function() {
      this.loadRatingData()
    },
    dateQuery: function() {
      this.loadRatingData()
    },
  }
}
</script>

<style scoped>

</style>