export default new class QuarterGenerator{

    generateQuarters(startDate) {
      let currentDate = new Date();
      let start_date = new Date(startDate);
      let quartersList = [];
      let id = 1;
        while (start_date <= currentDate) {
          var values =[];
          const year = start_date.getFullYear();
          values = this.checkQuartals(start_date, year)
          quartersList.push({
              id,
              name: values[0],
              datesPeriod: {
                   startDate: values[1],
                   endDate: values[2]
              }
          })
          id++;
          start_date = new Date(this.addDays(values[2], 1));
        }
        return quartersList;
    }

    checkQuartals(start_date, year){
      let quartal_1 = `${year}-12-1,${year+1}-02-${this.daysInMonth(2, year+1)}`.split(",");
      let quartal_2 = `${year}-03-1,${year}-05-31`.split(",");
      let quartal_3 = `${year}-06-1,${year}-08-31`.split(",");
      let quartal_4 = `${year}-09-1,${year}-11-30`.split(",")

      if (start_date >= new Date(quartal_2[0]) && start_date <= new Date(quartal_3[1])){
        if(start_date >= new Date(quartal_2[0]) && start_date <= new Date(quartal_2[1])){
          return [`2 кв. ${year}`, quartal_2[0], quartal_2[1]];
        }else{
          return [`3 кв. ${year}`, quartal_3[0], quartal_3[1]];
        }
      }else{
        if(start_date >= new Date(quartal_4[0]) && start_date <= new Date(quartal_4[1])){
          return [`4 кв. ${year}`, quartal_4[0], quartal_4[1]];
        }else{
          return [`1 кв. ${year+1}`, quartal_1[0], quartal_1[1]];
        }
      }
    }

    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }
}

